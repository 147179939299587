:root {
  --shadow: 0px 4px 8px rgba(32,33,36,0.50);
  --white: #E8EAED;
  --whiteTransparent: rgba(255,255,255,0.12);
  --lightGray: #9AA0A6;
  --gray: #55557A;
  --black: #202124;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
  background-color: var(--black);
}

h2 {
  margin: 0;
}

h1 {
  font-family: 'Cinzel Decorative', serif;
  font-weight: 900;
  letter-spacing: -0.5px;
  font-size: 42px;
}

::-webkit-scrollbar {
  display: none;
}

.MapTimeline .scroll-container {
  padding: 0 125px 98px;
  margin: 0 -20px -30px;
}
